<template>
  <v-container>
    <v-form>
      <v-col cols="12" md="12">
        <div class="d-flex align-center mt-4">
          <v-icon size="32" color="primary" class="">mdi-progress-check</v-icon>
          <span class="pl-5"
            >{{ $vuetify.lang.t("$vuetify.main.patientReg.stepFour.price")
            }}<b>{{ price }} &#8372;</b></span
          >
        </div>
        <div class="d-flex align-center mt-4">
          <v-icon size="32" color="primary">mdi-progress-check</v-icon>
          <span class="pl-5"
            >{{
              $vuetify.lang.t("$vuetify.main.patientReg.stepFour.termsPart1")
            }}
            <b>2 &#8372;</b>
            {{
              $vuetify.lang.t("$vuetify.main.patientReg.stepFour.termsPart2")
            }}
          </span>
        </div>
        <div class="d-flex align-center mt-4">
          <v-icon size="32" color="primary">mdi-progress-check</v-icon>
          <span class="pl-5"
            >{{
              $vuetify.lang.t("$vuetify.main.patientReg.stepFour.termsPart31")
            }}
            <b>{{
              $vuetify.lang.t("$vuetify.main.patientReg.stepFour.termsPart32")
            }}</b>
            {{
              $vuetify.lang.t("$vuetify.main.patientReg.stepFour.termsPart33")
            }}
          </span>
        </div>
        <div class="d-flex align-center mt-4">
          <v-icon size="32" color="primary">mdi-progress-check</v-icon>
          <span class="pl-5"
            >{{
              $vuetify.lang.t("$vuetify.main.patientReg.stepFour.termsPart41")
            }}
            <b>
              {{
                $vuetify.lang.t("$vuetify.main.patientReg.stepFour.termsPart42")
              }}
            </b>
            {{
              $vuetify.lang.t("$vuetify.main.patientReg.stepFour.termsPart43")
            }}
          </span>
        </div>
        <div class="d-flex align-center mt-4">
          <v-icon size="32" color="primary">mdi-progress-check</v-icon>
          <span class="pl-5">
            <b>
              {{
                $vuetify.lang.t("$vuetify.main.patientReg.stepFour.important")
              }}
            </b>
            {{
              $vuetify.lang.t("$vuetify.main.patientReg.stepFour.termsPart5")
            }}
          </span>
        </div>
        <div class="d-flex align-center mt-4 mb-4">
          <v-icon size="32" color="primary">mdi-progress-check</v-icon>
          <span class="pl-5"
            >{{
              $vuetify.lang.t("$vuetify.main.patientReg.stepFour.termsPart6")
            }}
          </span>
        </div>
        <v-divider></v-divider>
        <div class="d-flex mt-6">
          <v-avatar color="primary" size="100">
            <img
              :src="sessionInfo.questionnaire.psychologistPhoto"
              alt="Photo"
            />
          </v-avatar>
          <v-col md="8" align="start" class="d-flex flex-column">
            <p>
              {{
                $vuetify.lang.t(
                  "$vuetify.main.patientReg.stepFour.psychologist"
                )
              }}
              <span
                >{{ sessionInfo.questionnaire.psychologistName }}
                {{ sessionInfo.questionnaire.psychologistSurname }}</span
              >
            </p>
            <span
              >{{
                $vuetify.lang.t("$vuetify.main.patientReg.stepFour.sessionDate")
              }}
              <b
                >{{ sessionInfo.questionnaire.selectedSlot.date.translated }} в
                {{ sessionInfo.questionnaire.selectedSlot.slot.time }}</b
              ></span
            >
            <!--            <a class="text&#45;&#45;secondary">выбрать другое время</a>-->
          </v-col>
        </div>
      </v-col>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "stepFour",

  data() {
    return {
      price: "",
    };
  },
  beforeMount() {
    this.price = process.env.VUE_APP_SESSION_PRICE;
  },
  computed: {
    sessionInfo() {
      return this.$store.getters["user/getPatientQuestionnaire"];
    },
  },
};
</script>

<style scoped></style>
